import Card from "./UI/card";

const Portfolio = () => {
  return (
      <div className="portfolio-container">
          <Card/>
          <Card/>
          <Card/>
      </div>
  );
};

export default Portfolio;
